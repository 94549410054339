<template>
    <div>
        <page-settings :page="page"></page-settings>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Auth from "@/mixins/Auth";
    import ModalNavigation from "@/mixins/ModalNavigation";
    import PageSettings from "@/components/cms/websites/PageSettings";

    export default {
        components: { PageSettings },
        mixins: [ ModalNavigation, Auth ],
        data: () => {
            return {
                page: {
                    customers_id: null,
                    originalPageName: null,
                    website_pages_name: null,
                    url_alias: null,
                    show_in_nav: false,
                    is_home_page: false,
                    is_published: false,
                    seo_title: null,
                    seo_description: null,
                    seo_image_ul: null,
                },
                website: null,
            }
        },
        computed: {
            ...mapGetters({
                getWebsiteInfo: 'website_info/getWebsiteInfo'
            }),
        },
        async mounted() {
            this.website = this.getWebsiteInfo
            this.page.websites_id = this.website.id;
            this.page.communities_id = this.website.communities_id;
            this.page.customers_id = this.customerId;
        },
    }
</script>